import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 1000px;
  height: max-content;

  left: 410px;
  top: 200px;
`;
