
export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Quem Somos',
    headline: 'Sediada em Luanda ',
    description: 'Registrada Pela Sociedade de direito Angolano Invesparso, a Luanda Office Center é uma empresa orientada para o mercado empresarial angolano, que cria e implementa soluções que visam potencializar as vendas e a notariedade de imagem das empresas',
    buttonLabel: 'Contacto',
    imgStart: false,
    img: require('../../images/sede.png'),
    alt: 'Sede',
    dark: true,
    primary: true,
    darkText: true,
}

export const homeObjTwo = {
    id: 'obj2',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Unlimited Access',
    headline: 'Login to your account at any time',
    description: 'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/png1.png'),
    alt: 'Bank',
    dark: false,
    primary: false,
    darkText: true,
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Entre em contato connosco',
    headline: 'Envie uma mensagem a nossa equipe para mais informações',
    description: 'receba qualquer informação que pretenda por email ou ligue-nos',
    buttonLabel: 'Mensagem',
    imgStart: false,
    img: require('../../images/pngM.png'),
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true,
}