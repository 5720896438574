import React from "react";
import { Container } from "./styles";
import DataTable from "./table";
const AssignProjectDataTable = () => {
  return (
    <Container>
      <DataTable />
    </Container>
  );
};

export default AssignProjectDataTable;
